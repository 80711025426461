<template>
	<div class="calendar_horse_content">
		<div v-if="!params.mare && !params.tiers && !params.contact" class="text-center">
			<div class="d-flex d-md-block d-lg-flex justify-content-around">
				<button class="btn btn-secondary mr-2 mb-2" @click="unlock_creneau"><font-awesome-icon :icon="['far', 'lock']" /></button>
				<b-button pill variant="primary py-1 px-2" @click="edit">
					<font-awesome-icon :icon="['fal', 'marker']"></font-awesome-icon><span class="d-none d-md-inline d-lg-none"> {{ $t('global.editer') }}</span>
				</b-button>
			</div>
			<div class="my-1">
				{{ commentaire_formatted }}
			</div>
		</div>
		<template v-else>
			<div class="d-flex d-md-block d-lg-flex justify-content-between">
				<div>
					<div class="d-md-none d-inline">
						{{ schedule.schedule_start }}h {{ schedule.schedule_end }}h
					</div>
					<template v-if="params.mare && params.mare.horse_nom">
						<router-link :to="{ name: 'horseFiche', params: { horse_id: params.mare.horse_id }}">
							<b>{{ params.mare.horse_nom }}</b>
						</router-link>
						<br>
						<small>{{ params.mare.horse_transpondeur }}</small>
						<br>
						<template v-if="params.prev_sms">
							{{ params.prev_sms.seasonmarestallion_status }}
						</template>
						<template v-else-if="params.season_mare.seasonmare_status">
							{{ $t('gynecologie.'+params.season_mare.seasonmare_status) }}
						</template>
						<br>
					</template>
					<template v-if="params.contact">
						{{ params.contact.contact_firstname }} {{ params.contact.contact_lastname }}
						<br>
						<template v-if="params.contact.phone.length > 0">
							{{ formatPhone(params.contact.phone[0]) }}
						</template>
					</template>
					<template v-else-if="params.tiers">
						{{ params.tiers.tiers_rs }}
						<br>
						<template v-if="params.tiers.phone.length > 0">
							{{ formatPhone(params.tiers.phone[0]) }}
						</template>
					</template>
				</div>
				<div>
					<b-button pill variant="primary py-1 px-2 calendar_btn_edit" @click="edit">
						<font-awesome-icon :icon="['fal', 'marker']"></font-awesome-icon><span class="d-none d-md-inline d-lg-none"> {{ $t('global.editer') }}</span>
					</b-button>
					<b-button pill variant="secondary py-1 px-2 calendar_btn_edit" @click="deleteSchedule">
						<font-awesome-icon :icon="['fal', 'trash-alt']"></font-awesome-icon><span class="d-none d-md-inline d-lg-none"> {{ $t('global.supprimer') }}</span>
					</b-button>
				</div>
			</div>
			<div class="my-1">
				{{ commentaire_formatted }}
			</div>
			<div v-if="params.mare && params.mare.horse_nom" class="d-flex justify-content-between">
				<b-button v-if="!contract" :class="class_contract" size="sm" variant="secondary p-1 mr-1 mx-lg-0" @click="createContract">
					<font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon><span class="d-md-none d-lg-inline"> {{ $t('monte.contrat_a_faire') }}</span>
				</b-button>
				<b-button v-else-if="contract && contract.last_avenant.avenant_status == 3" class="green" size="sm" variant="secondary p-1 mx-1 mx-lg-0"  @click="openContract">
					<font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon>
					<span class="d-md-none d-lg-inline"> {{ $t('monte.contrat_signe') }} </span>
					<span :class="class_contract"> €</span>
				</b-button>
				<b-button v-else class="red" size="sm" variant="secondary p-1 mx-1 mx-lg-0" @click="openContract">
					<font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon>
					<span class="d-md-none d-lg-inline"> {{ $t('monte.contrat_a_signe') }} </span>
					<span :class="class_contract"> €</span>
				</b-button>

				<b-button :class="params.season_mare && params.season_mare.seasonmare_sanitary ? 'green' : 'red'" size="sm" variant="secondary p-1 mx-1 mx-lg-0" @click="openActe"> <!-- TODO -->
					<font-awesome-icon :icon="['fal', 'syringe']"></font-awesome-icon><span class="d-md-none d-lg-inline"> {{ $t('monte.sanitaire') }}</span>
				</b-button>
				<b-button size="sm" :class="params.saillie" variant="secondary p-1 mx-1 mx-lg-0" @click="openAddActe" v-b-tooltip.hover.top :title="$t('monte.info_couleur_saillie')">
					<font-awesome-icon :icon="['fal', 'venus-mars']"></font-awesome-icon><span class="d-md-none d-lg-inline"> {{ $t('monte.saillie') }}</span>
				</b-button>
			</div>
		</template>
	</div>
</template>

<style scoped>
	.btn.red {
		border-color: #F21207;
		color: #F21207;
	}
	.btn.green {
		border-color: #569E18;
		color: #569E18;
	}
	.btn.orange {
		border-color: darkorange;
		color: darkorange;
	}
</style>

<script type="text/javascript">
	import Vue from 'vue'
	import Contract from '@/mixins/Contract'
	import Tiers from '@/mixins/Tiers'
	import Gynecologie from '@/mixins/Gynecologie'
	import Shutter from '@/mixins/Shutter'
	import MonteShutters from '@/mixins/shutters-manager/Monte'
	import { parsePhoneNumberFromString as parseMax, AsYouType } from 'libphonenumber-js/max'

	export default {
		name: 'CellCalendarMainBeta',
		mixins: [Contract, Tiers, Gynecologie, Shutter, MonteShutters],
		props: ['schedule', 'params', 'edit_schedule', 'delete_schedule', 'delete_schedule_force'],
		data () {
			return {
				contract: null,
				mare_status: null,
				season_mare: null,
				class_contract: '',
				sms: null,
				saillie: [],
				avenant: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.params.contract) {
					this.class_contract = this.params.solde ? 'd-none' : 'text-danger'
					this.contract = this.params.contract
				}
			},

			edit() {
				this.$emit('update:edit_schedule', {schedule_info: this.params, schedule_id: this.schedule.schedule_id, horse_id: this.params.stallion.horse_id, season_mare: this.season_mare})
			},

			openActe() {
				if(this.shutterPanel().isOpen('acte-jument')) {
					this.shutterPanel().close('acte-jument')
					return false
				}

                this.shutterPanel().keepGiven('monte-main-calendar-beta')
				const shutter = this.monteShutters['acte-jument']
				shutter.props.horse_id = this.params.mare.horse_id
				shutter.props.horse_nom = this.params.mare.horse_nom

				shutter.onOk = () => {
					this.shutterPanel().close('acte-jument')
				}

				this.shutterPanel().open(shutter)
			},

			openAddActe() {
				if(this.shutterPanel().isOpen('ajout-acte')) {
					this.shutterPanel().close('ajout-acte')
					return false
				}

				this.shutterPanel().keepGiven('monte-main-calendar-beta')
				const shutter = this.monteShutters['ajout-acte']
				shutter.props.horses_ids = [this.params.mare.horse_id]
				shutter.props.actes_ids = this.params.saillie_id ? [this.params.saillie_id] : []
				shutter.props.group_preselect = "REPRO"
				shutter.props.type_preselect = "REPRO_SAILLIE_EN_MAIN"
				shutter.props.date_preselect = this.params.saillie_id ? null : new Date(this.params.date)
				shutter.props.preselect = this.params.stallion.horse_nom

				shutter.onOk = () => {
					this.shutterPanel().close('ajout-acte')
				}

				this.shutterPanel().open(shutter)
			},

			async createContract() {
				if(this.params.mare.horse_id) {
					const tiers = await this.loadTiersHorse(this.params.mare.horse_id)
					if(tiers.length == 0) {
						this.failureToast("monte.jument_no_tiers")
						this.showAjoutTiers()
						return false
					}

					const contract_id = await this.addContractOnlineFromHorse(this.params.mare.horse_id)
					this.infos_contract = await this.getContractOnline(contract_id)
					this.contract = this.infos_contract[0].contract
					this.contract.last_avenant = this.infos_contract[0].avenant
				} else {
					const contract_id = await this.addContractOnline()
					this.infos_contract = await this.getContractOnline(contract_id)
					this.contract = this.infos_contract[0].contract
					this.contract.last_avenant = this.infos_contract[0].avenant
				}
				this.openContract()
			},

			showAjoutTiers() {
				this.shutterPanel().keepGiven('monte-main-calendar-beta')
				const shutter = this.monteShutters['ajout-tiers-mare']
				shutter.props.horse = this.params.mare

				shutter.onOk = () => {
					this.shutterPanel().close('ajout-tiers-mare')
					this.createContract()
				}

				this.shutterPanel().open(shutter)
			},

			openContract() {
				if(this.shutterPanel().isOpen('contrat')) {
	                this.shutterPanel().close('contract-a-valider')
	                this.shutterPanel().close('contract-bons-valider')
	                this.shutterPanel().close('contract-invoices')
					this.shutterPanel().close('contrat')
					return false
				}

				this.shutterPanel().keepGiven('monte-main-calendar-beta')
				const shutter = this.monteShutters['contrat']
				shutter.props.contract_id = this.contract.contract_id
				shutter.props.stallion_id = this.params.stallion.horse_id

				shutter.onOk = () => {
					this.shutterPanel().close('contrat')
					this.init_component()
				}

				this.shutterPanel().open(shutter)
			},

			deleteSchedule() {
				this.$emit('update:delete_schedule', {schedulehorse_id: this.params.id})
			},

			formatPhone(number) {
				return new AsYouType(Vue.i18n.locale()).input(number)
			},

			unlock_creneau() {
				this.$emit('update:delete_schedule_force', {schedulehorse_id: this.params.id})
			}
		},

		computed: {
			commentaire_formatted() {
				return this.params.commentaire //.substr(0, 25) + '...'
			}
		}
	}

</script>